import 'script-loader!../src/vendor/slick'

$(document).on('turbo:load', function() {
    $('#home-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
    });
    $('.product-slide').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 479,
                settings: {
                    vertical: false,
                    slidesPerRow: 1,
                    slidesToShow: 2,
                }
            },
        ]
    });
});